<template>
  <div class="footer">
    <component
      class="footer__option"
      :is="icon"
      @click="$emit('open-modal')"
      :class="iconColor"
    />
    <span @click="$emit('open-modal')" v-tooltip="title">
      {{ title | truncate(10) }}
    </span>
    <div>
      <hcc-dropdown customClass="custom-position">
        <template #button>
          <hcc-button-icon
            :color="iconColor"
            :muted="false"
            class="footer__icon"
            v-tooltip="$t('campaigns.data-bank.more-actions')"
            ><more-icon
          /></hcc-button-icon>
        </template>
        <hcc-dropdown-item
          v-if="edit"
          @click="$emit('edit-option')"
          icon="pencil-icon"
        >
          <span>{{ editOption }}</span>
        </hcc-dropdown-item>
        <hcc-dropdown-item
          v-else
          @click="$emit('download')"
          icon="download-icon"
        >
          <span>{{ downloadOption }}</span>
        </hcc-dropdown-item>
        <hcc-dropdown-item @click="$emit('delete')" icon="delete-icon">
          <span>{{ deleteOption }}</span>
        </hcc-dropdown-item>
      </hcc-dropdown>
    </div>
  </div>
</template>

<script>


export default {
  components: {
    HccButtonIcon: () => import('@/components/shared/HccButtonIcon/index.vue'),
    HccDropdown: () => import('@/components/shared/HccDropdown/index.vue'),
    HccDropdownItem: () => import('@/components/shared/HccDropdown/DropdownItem.vue'),
  },
  data() {
    return {
      downloadOption: this.$t('campaigns.data-bank.download'),
      deleteOption: this.$t('common.delete'),
      editOption: this.$t('common.edit'),
    };
  },
  props: {
    title: {
      required: true,
      type: String,
    },
    icon: {
      required: true,
    },
    iconColor: {
      type: String,
      default: 'secondary',
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },


};
</script>

<style scoped lang="scss">
@import "~styles/components/settings/dataBank/_footer_media.scss";
</style>
